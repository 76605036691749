export default {
    main: '#0c61a1',
    gray_bg: '#dcdee0',
    darkblue_bg: '#0c61a1',
    darkblue_text: '#052f63',
    red_main: '#b50d34',
    tussock: '#d5840b',
    pale_blue: '#edf2f5',
    main_light: '#83b7de'
};
